import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { IPost } from '~/types'
import styled from 'styled-components'
import YoutubeView from '~/components/media-views/YoutubeView'
import AudioView from '~/components/media-views/AudioView'
import CoverView from '~/components/media-views/CoverView'
import { contentWidth, height16x9, height4x3, linesRem } from '~/utils/styling'
import Ratio from '~/components/Ratio'
import ReadMore from '~/components/ReadMore'
import { GatsbyImageSharpFluidFragment } from '~/types'
import Tag from '~/components/Tag'
import { useAppContext } from '~/contexts/appContext'
import SharePanel from '~/components/SharePanel'
import Carousel, { Modal, ModalGateway } from 'react-images'
import bgdark from '~/assets/bg-dark-3@2x.png'
import RutubeView from './media-views/RutubeView'

interface IAnnounceViewProps {
  post: IPost
}

const Article = styled.div`
  background: #42196d;
  color: #f1f1f1;
  overflow: hidden;
  /* box-shadow: 0 5px 20px -3px #2D0C3F; */
  padding-bottom: 10px;
  a {
    /* color: rgba(255, 255, 255, 0.99); */
    color: hsl(200, 88%, 70%);
    &:link {
      color: hsl(200, 88%, 70%);
    }
    &:visited {
      /* color: rgba(255, 255, 255, 0.65); */
      color: hsl(200, 88%, 70%);
    }
    &:hover {
      color: #d386ff;
    }
    &:active {
      color: #e0c1ff;
    }
  }
  header: {
    padding: 20px 15px 0;
  }
  .html {
    padding: 0 15px;
  }
  h2 {
    text-shadow: none !important;
  }
  p {
    text-shadow: none !important;
  }
`

const CoverWrapper = styled.div`
  width: 100%;
  max-width: ${contentWidth}px;
`

const CarouselWrapper = styled.div`
  width: 100%;
  background-image: url(${bgdark});
  background-size: 160px 160px;
  overflow: hidden;
`

const ImgView: React.FC<{ data: GatsbyImageSharpFluidFragment }> = ({ data }) => (
  <Img
    fluid={data}
    style={{
      maxWidth: `1120px`,
      maxHeight: `640px`,
      margin: `auto`,
    }}
    //@ts-ignore
    objectFit="cover"
    objectPosition="50% 50%"
  ></Img>
)

const AnnounceView: React.FC<IAnnounceViewProps> = ({post}) => {
  const { state } = useAppContext()
  let image = null
  let video = null

  let objectFit = 'cover'
  let maxHeight = '700px'
  if (post.imageOptions) {
    if (post.imageOptions.maxHeight === 0) {
      maxHeight = 'auto'
    } else {
      maxHeight = `${maxHeight}px`
    }
    if (post.imageOptions.objectFit === 'contain') {
      objectFit = 'contain'
    }
  }

  if (post.type === 'video' && post.youtube) {
    video = <YoutubeView link={post.youtube} />
  }

  if (post.type === 'video' && post.rutube) {
    video = <RutubeView link={post.rutube} />
  }


  const [modalIsOpen, setModalIsOpen] = useState(false)

  function toggleModal() {
    if (state.pageWidth <= 400) return
    setModalIsOpen(!modalIsOpen)
  }
  
  if (post.cover) {
    if (post.images) {
      image = (
        <CoverWrapper>
          <CarouselWrapper>
            <Carousel views={post.images} components={{ Footer: null, ImgView }} />
          </CarouselWrapper>
        </CoverWrapper>
      )
    } else {
      image = (
        <CoverWrapper onClick={() => navigate(post.urlPath)}>
          <div className="img">
            <Img
              fluid={post.cover as GatsbyImageSharpFluidFragment}
              style={{
                maxHeight,
              }}
              imgStyle={{
                objectFit,
              }}
            />
          </div>
        </CoverWrapper>
      )
    }
    
  }

  let html = post.html
  let link = undefined
  if (/<!--\s*more\s*-->/i.test(post.html)) {
    const parts1 = post.html.split(/<!--\s*more\s*-->/i)
    html = parts1[0]
    link = post.urlPath

    if (/<!--\s*more_end\s*-->/i.test(parts1[1])) {
      const parts2 = parts1[1].split(/<!--\s*more_end\s*-->/i)
      html += '\n' + parts2[1]
    }
  }

  return (
    <Article className="fixed-width">
      <div>
        {image}
        {video}
      </div>
      <header style={{ padding: `20px 20px 0` }}>
        <h2>
          <Link to={post.urlPath}>{post.title}</Link>
        </h2>
        {/* <h6>
          <div style={{ display: `flex`, flexDirection: `row`, alignItems: `stratch` }}>
            <span style={{ flex: `1`}}></span>
            <SharePanel link={post.urlPath}></SharePanel>
          </div>
        </h6> */}
      </header>
      <main style={{ padding: `0 20px 20px` }}>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </main>
    </Article>
  )
}

export default AnnounceView